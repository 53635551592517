/* eslint-disable */
import client from '@/http/client';

// Utils
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/payments';

class PaymentsService {
  getMethods({ limit, offset } = {}) {
    return client.get(`${BASE_URL}/clients/payment-methods/`, {
      params: {
        limit,
        offset,
      },
    });
  }

  async getSessionURI() {
    const redirectUri = window.location.href;

    const { url } = await client.get(`${BASE_URL}/clients/payment-methods/creation-session-url/`, {
      params: {
        redirectUri,
      },
    });

    return url;
  }

  createPayment({ invoice, paymentMethod } = {}) {
    return client.post(`${BASE_URL}/clients/payments/`, {
      invoice,
      paymentMethod,
    });
  }

  deleteMethod(paymentMethodId) {
    return client.delete(`${BASE_URL}/clients/payment-methods/${paymentMethodId}/`);
  }

  getEmployeesInvoices({
    limit,
    offset,
    orderBy,
    unpaidOnly,
    search,
    status,
    invoiceDateAfter,
    invoiceDateBefore,
    paymentDateAfter,
    paymentDateBefore,
    projects,
    buildings,
    units,
    clients,
    paymentTypes,
    serviceTypes,
    config,
  } = {}) {
    return client.post(
      `${BASE_URL}/select/invoices/`,
      {
        projects,
        buildings,
        units,
        clients,
        paymentTypes,
        serviceTypes,
      },
      {
        ...config,
        params: {
          limit,
          offset,
          orderBy,
          unpaidOnly,
          search,
          status,
          invoiceDateAfter,
          invoiceDateBefore,
          paymentDateAfter,
          paymentDateBefore,
        },
      }
    );
  }

  getEmployeesRecurrenceInvoices({ limit, offset, orderBy, config, search }) {
    return client.get(`${BASE_URL}/employees/recurring/`, {
      ...config,
      params: {
        limit,
        offset,
        orderBy,
        search,
      },
    });
  }

  getClientsInvoices({ limit, offset, orderBy, unpaidOnly, search } = {}) {
    return client.get(`${BASE_URL}/clients/invoices/`, {
      params: {
        limit,
        offset,
        orderBy,
        unpaidOnly,
        search,
      },
    });
  }

  getPaymentTypes({config} = {}) {
    return client.get(`${BASE_URL}/select/payment-types/`, {}, {...config});
  }

  getNewInvoicesCount() {
    return client.get(`${BASE_URL}/clients/invoices/new/`);
  }
  
  updatePaymentType({data, config} = {}) {
    return client.post(`${BASE_URL}/employees/invoices/payment/`,
     data, {
      ...config,
    })
  }

  deleteInvoice(invoiceId) {
    return client.delete(`${BASE_URL}/employees/invoices/${invoiceId}/`);
  }

  createInvoice({
    clientId,
    unit,
    contractor,
    service,
    invoiceNumber,
    invoiceDate,
    period,
    periodEnd,
    periodType,
    isOneTime,
    services,
    serviceType,
    vatCode,
    department,
    dueDate,
  } = {}) {
    return client.post(`${BASE_URL}/employees/invoices/`, {
      client: clientId,
      unit,
      contractor,
      service,
      invoiceNumber,
      invoiceDate,
      period,
      periodType,
      periodEnd,
      isOneTime,
      services,
      serviceType,
      vatCode,
      department,
      dueDate,
    });
  }

  exportRecurringInvoices() {
    return client.getRaw(`${BASE_URL}/employees/recurring/xlsx/`, {
      responseType: 'blob',
    });
  }

  exportInvoices({
    invoiceDateAfter,
    invoiceDateBefore,
    paymentDateAfter,
    paymentDateBefore,
    projects,
    buildings,
    clients,
    units,
  } = {}) {
    return client.postRaw(
      `${BASE_URL}/employees/invoices/xlsx/export/`,
      {
        projects,
        buildings,
        clients,
        units,
      },
      {
        params: {
          invoiceDateAfter,
          invoiceDateBefore,
          paymentDateAfter,
          paymentDateBefore,
        },
        responseType: 'blob',
      }
    );
  }

  report({
    invoiceDateAfter,
    invoiceDateBefore,
    paymentDateAfter,
    paymentDateBefore,
    projects,
    buildings,
    units,
    clients,
    status,
  }) {
    return client.postRaw(
      `${BASE_URL}/employees/invoices/xlsx/report/`,
      {
        projects,
        buildings,
        units,
        clients,
      },
      {
        params: {
          invoiceDateAfter,
          invoiceDateBefore,
          paymentDateAfter,
          paymentDateBefore,
          status,
        },
        responseType: 'blob',
      }
    );
  }

  importInvoices(file) {
    return client.post(`${BASE_URL}/employees/invoices/xlsx/import/`, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  importRecurringInvoices(file) {
    return client.post(`${BASE_URL}/employees/recurring/xlsx/`, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  importStatus() {
    return client.get(`${BASE_URL}/employees/invoices/xlsx/status/`, {
      params: {
        // Disable cache
        timestamp: Date.now(),
      },
    });
  }

  importRecurringStatus() {
    return client.get(`${BASE_URL}/employees/recurring/xlsx/status/`, {
      params: {
        // Disable cache
        timestamp: Date.now(),
      },
    });
  }

  async downloadReport(fallbackFilename, filters) {
    const response = await this.report(filters);

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  async downloadInvoices(fallbackFilename, config) {
    const response = await this.exportInvoices(config);

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  async downloadRecurringInvoices(fallbackFilename) {
    const response = await this.exportRecurringInvoices();

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  setDefaultPayment(paymentId) {
    return client.patch(`${BASE_URL}/clients/payment-methods/${paymentId}/set-default/`);
  }

  notification(invoices) {
    return client.post(`${BASE_URL}/employees/invoices/notifications/`, {
      invoices,
    });
  }

  notificationAll() {
    return client.post(`${BASE_URL}/employees/invoices/notifications/all/`);
  }

  createRecurrenceInvoice({
    invoiceNumber,
    invoiceDateFirst,
    invoiceDateLast,
    recurrencePeriod,
    clientId,
    unit,
    contractor,
    serviceType,
    services,
    vatCode,
    department
  }) {
    return client.post(`${BASE_URL}/employees/recurring/`, {
      invoiceNumber,
      invoiceDateFirst,
      invoiceDateLast,
      recurrencePeriod,
      client: clientId,
      unit,
      contractor,
      serviceType,
      services,
      vatCode,
      department
    });
  }

  getRecurrenceInvoice({ id }) {
    return client.get(`${BASE_URL}/employees/recurring/${id}/`);
  }

  updateRecurrenceInvoice({
    id,
    invoiceNumber,
    invoiceDateFirst,
    invoiceDateLast,
    recurrencePeriod,
    clientId,
    unit,
    contractor,
    serviceType,
    services,
    vatCode,
    department
  }) {
    return client.patch(`${BASE_URL}/employees/recurring/${id}/`, {
      invoiceNumber,
      invoiceDateFirst,
      invoiceDateLast,
      recurrencePeriod,
      client: clientId,
      unit,
      contractor,
      serviceType,
      services,
      vatCode,
      department
    });
  }

  deleteRecurrenceInvoice(id) {
    return client.delete(`${BASE_URL}/employees/recurring/${id}/`);
  }

  // deleteRecurrenceInvoice(invouces) {
  //   return client.delete(`${BASE_URL}/employees/recurring/`, {
  //    invoices,
  //  });
  // }
}

export default new PaymentsService();
