<template>
  <form-builder
    ref="formBuilder"
    v-model="valueModel"
    enable-cache
    remove-label-suffix
    class="invoices-filters"
    :schema="schema"
    :initial-data="value"
  >
  </form-builder>
</template>

<script>
// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema, schemaWithoutPaymentPeriod } from '@/schemas/invoiceFilter.schema';

// Utils
import { createModelWrapper } from '@/utils/components';

// Constants
import { UNPAID } from '@/constants/entityStatuses';

export default {
  name: 'InvoicesFilters',

  components: { FormBuilder },

  props: {
    value: { type: Object, default: () => ({}) },
    status: { type: String, required: true },
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),

    isUnpaid() {
      return this.status === UNPAID;
    },

    schema() {
      if (this.isUnpaid) {
        return schemaWithoutPaymentPeriod;
      }

      return schema;
    },
  },

  methods: {
    reset() {
      this.$refs.formBuilder.reset();
    },
  },
};
</script>

<style lang="scss">
.invoices-filters {
  .v-form {
    display: flex;
    column-gap: 8px;
    // display: grid;
    // grid-template-columns: repeat(7, 1fr);
    // grid-gap: 8px;
  }
}
</style>
